<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="#FFABAB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.19526 6.19526C6.45561 5.93491 6.87772 5.93491 7.13807 6.19526L12.6667 11.7239V6.66667C12.6667 6.29848 12.9651 6 13.3333 6C13.7015 6 14 6.29848 14 6.66667V13.3333C14 13.7015 13.7015 14 13.3333 14H6.66667C6.29848 14 6 13.7015 6 13.3333C6 12.9651 6.29848 12.6667 6.66667 12.6667H11.7239L6.19526 7.13807C5.93491 6.87772 5.93491 6.45561 6.19526 6.19526Z"
      fill="#991C1B"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
