<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="#99E4BC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.66667 7.33333C6.29848 7.33333 6 7.03486 6 6.66667C6 6.29848 6.29848 6 6.66667 6H13.3333C13.7015 6 14 6.29848 14 6.66667V13.3333C14 13.7015 13.7015 14 13.3333 14C12.9651 14 12.6667 13.7015 12.6667 13.3333V8.27614L7.13807 13.8047C6.87772 14.0651 6.45561 14.0651 6.19526 13.8047C5.93491 13.5444 5.93491 13.1223 6.19526 12.8619L11.7239 7.33333H6.66667Z"
      fill="#007134"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
